<template>
  <div class="is-clearfix">
    <b-table
      :data="orderedDelegates"
      :mobile-cards="true"
      :default-sort="['dateCreated', 'desc']"
      :loading="loading"
      hoverable
      striped
      class="has-vertical-align-middle"
    >
      <template slot="empty">
        <no-results
          :cta="{ label: 'Add delegate' }"
          icon="box-open"
          title="No delegate users"
          message="You've not added any delegates yet."
          @click="$emit('addDelegate')"
        />
      </template>

      <template slot-scope="props">
        <b-table-column label="Delegate" field="email" sortable>
          {{ props.row.email }}
          <status-label
            v-if="props.row.isResellerClient"
            :status="'reseller-client'"
            class="has-margin-left-50"
          />
        </b-table-column>

        <b-table-column
          label="Created"
          field="dateCreated"
          sortable
          width="200"
          >{{ $moment(props.row.dateCreated.toDate()).from() }}</b-table-column
        >

        <b-table-column width="40">
          <a role="button" @click="$emit('removeDelegate', props.row)">
            <b-icon icon="trash" class="has-text-danger" size="is-small" />
          </a>
        </b-table-column>
      </template>
    </b-table>

    <a
      v-if="orderedDelegates.length"
      role="button"
      class="button is-success is-pulled-right has-margin-top-50"
      @click="$emit('addDelegate')"
      >Add delegate</a
    >
  </div>
</template>

<script>
export default {
  name: "DelegatesTable",
  props: {
    delegates: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    orderedDelegates() {
      return this.$_.orderBy(this.delegates, ["dateCreated"], ["desc"]);
    }
  }
};
</script>
